<template>
    <div id="cont_Main" class="">
        <div class="main-content 
                    flex-1 
                    bg-gray-100 
                    mt-12 md:mt-12">
            <div class="bg-gray-800 pt-3">
                <div
                    class="
                    rounded-tl-3xl
                    bg-gradient-to-r
                    from-blue-900
                    to-gray-800
                    p-4
                    shadow
                    text-2xl text-white
                    "
                >
                <div v-if="infoAgentesWolkvoxIdCampActiva !== undefined">
                    <div v-if="infoAgentesWolkvoxIdCampActiva !== 0" class="absolute block transform-gpu transition ease-in-out hover:scale-105 duration-300">
                        <ul class="list-reset cont_Camp-Wolkvox">
                            <li class="Camp-Wolkvox font-sans">
                                <img src="/img/Wolkvox_Agent_Icon.png" alt="">
                                <span># {{ infoAgentesWolkvoxIdCampActiva }}</span>
                            </li>
                        </ul>      
                    </div> 
                </div>
                <h3 class=" relative inline-block font-bold pl-2 font-sans">Gestión de Factura # {{ facturasCliente[0].numFactura }} de C{{ facturasCliente[0].campaniaFactura.substring(4,6) }}-{{ facturasCliente[0].campaniaFactura.substring(0,4) }}  </h3>
                </div>
            </div>
            <div id="cont_ppal" class="w-full md:w-10/12 mx-auto p-6 bg-white shadow-xl rounded-b-md z-10">
                <div id="cont_id" 
                    class="grid 
                            grid-cols-1
                            md:grid-cols-6 
                            gap-2
                            md:gap-6">
                    <div id="cont_Cedula" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Cédula: </span> 
                        <input type="number" 
                            id="Cedula" 
                            v-model="Cedula"
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Cedula Cliente" disabled>
                    </div>
                    <div id="cont_Nombre" 
                        class="relative 
                                inline-block 
                                md:col-span-2">
                        <span class=" relative block w-full text-left">Nombre:</span> 
                        <input type="text" 
                            id="NombreCliente" 
                            v-model="NombreCliente" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Nombre Completo" 
                            disabled>
                    </div>
                    <div id="cont_TipoFacturacion" 
                        class="relative 
                                inline-block">
                        <span class=" relative block w-full text-left">Tipo Facturacion:</span> 
                        <input type="text" 
                            id="TipoFacturacion" 
                            v-model="tipoFacturacion" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Tipo Facturacion" 
                            disabled>
                    </div>
                    <div id="cont_CorteZona" 
                        class="relative 
                                inline-block
                                grid
                                grid-cols-2
                                gap-6"> 
                        <div id="cont_Zona">
                            <span class=" relative block w-full text-left">Zona:</span> 
                            <input type="number" 
                                id="ZonaCliente" 
                                v-model="ZonaCliente" 
                                class="w-full 
                                        border-solid 
                                        border-gray-600 
                                        border-b-2
                                        rounded-md" 
                                placeholder="Zona" disabled>
                        </div>
                        <div id="cont_Corte">
                            <span class=" relative block w-full text-left">Corte:</span> 
                            <input type="text" 
                                id="CorteCliente" 
                                v-model="CorteCliente" 
                                class="w-full 
                                        border-solid 
                                        border-gray-600 
                                        border-b-2
                                        rounded-md" 
                                placeholder="Corte" disabled>
                        </div>
                    </div>
                    <div id="cont_IDCliente" 
                    class="relative 
                            inline-block">
                        <span class=" relative block w-full text-left">ID Cliente:</span> 
                            <div class=" grid grid-cols-4 gap-2">
                                <input type="text" 
                                    id="IDCliente" 
                                    v-model="idCliente" 
                                    class="w-full 
                                            border-solid 
                                            border-gray-600 
                                            border-b-2
                                            rounded-md
                                            col-span-3" 
                                    placeholder="ID Cliente" 
                                    disabled>
                                <button id="btn_Historico"
                                        class="flex
                                            w-full
                                            bg-blue-600
                                            rounded-lg
                                            border-solid
                                            border-blue-500
                                            border-b-2
                                            text-white
                                            font-bold
                                            col-span-1"
                                        @click="abrirInfoCliente()">
                                    <i class="fas fa-address-book mx-auto my-auto"></i>
                                </button>
                            </div>
                    </div>
                </div>
                <div id="cont_InfoCC" 
                    class="grid 
                            grid-cols-1
                            md:grid-cols-6 
                            gap-6
                            mt-4"
                    v-if="cuentaCorriente == true">
                    <div id="cont_ActCat" 
                        class="relative 
                                inline-block
                                grid
                                grid-cols-2
                                gap-6"> 
                        <div id="cont_Actividad">
                            <span class=" relative block w-full text-left">Actividad:</span> 
                            <input type="number" 
                                id="ActividadCliente" 
                                v-model="actividadCliente" 
                                class="w-full 
                                        border-solid 
                                        border-gray-600 
                                        border-b-2
                                        rounded-md" 
                                placeholder="Actividad" disabled>
                        </div>
                        <div id="cont_Categoria">
                            <span class=" relative block w-full text-left">Clasificacion:</span> 
                            <input type="text" 
                                id="CategoriaCliente" 
                                v-model="clasificacionCliente" 
                                class="w-full
                                        border-solid 
                                        border-gray-600 
                                        border-b-2
                                        rounded-md" 
                                placeholder="Categoria" disabled>
                        </div>
                    </div>
                    <div id="cont_Cupo">
                        <span class=" relative block w-full text-left">Cupo:</span> 
                        <input type="number" 
                            id="CupoCliente" 
                            v-model="cupoCliente" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Cupo" disabled>
                    </div>
                    <div id="cont_Devolucion">
                        <span class=" relative block w-full text-left">Devolucion:</span> 
                        <input type="number" 
                            id="DevolucionCliente" 
                            v-model="devolucionCliente" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Devolucion" disabled>
                    </div>
                    <div id="cont_Facturado">
                        <span class=" relative block w-full text-left">Facturado:</span> 
                        <input type="number" 
                            id="FacturadoCliente" 
                            v-model="facturadoCliente" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Facturado" disabled>
                    </div>
                    <div id="cont_Pagos">
                        <span class=" relative block w-full text-left">Pagos:</span> 
                        <input type="number" 
                            id="PagosCliente" 
                            v-model="pagosCliente" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Pagos" disabled>
                    </div>
                    <div id="cont_Saldo">
                        <span class=" relative block w-full text-left">Saldo:</span> 
                        <input type="number" 
                            id="SaldoCliente" 
                            v-model="saldoCliente" 
                            class="w-full
                                    border-solid 
                                    border-gray-600 
                                    border-b-2
                                    rounded-md" 
                            placeholder="Saldo" disabled>
                    </div>
                </div>
                <div id="cont_nav" 
                    class="my-4">
                    <div id="nav_header"
                        class="relative block  mx-auto grid grid-cols-1 gap-6  px-0 md:px-16" :class="permisosUsuario.cc_Cobranza ? 'md:grid-cols-3' : 'md:grid-cols-2' ">
                        <div id="cont_BotonNuevoComp" class=" relative inline-block w-full p-2">
                            <button id="btn_NuevaCobranza"
                                    class="flex 
                                        w-full
                                        bg-green-600
                                        rounded-lg
                                        border-solid
                                        border-green-500
                                        border-b-2
                                        p-2
                                        text-white
                                        font-bold"
                                    @click="NuevaCobranza()">
                                <i class="fas fa-calendar-plus my-auto mx-3"></i> <span class="mx-auto">Añadir Cobranza</span> <i class="w-5 my-auto"></i>
                            </button>
                        </div>
                        <div id="cont_BotonAbrirGrid" class=" relative inline-block w-full p-2">
                            <button id="btn_Historico"
                                    class="flex 
                                        w-full
                                        bg-blue-600
                                        rounded-lg
                                        border-solid
                                        border-blue-500
                                        border-b-2
                                        p-2
                                        text-white
                                        font-bold"
                                    @click="abrirGrid()">
                                <i class="fas fa-table my-auto mx-3 "></i> <span class="mx-auto">Gestión Cobranza</span> <i class="w-5 my-auto"></i>
                            </button>
                        </div>
                        <div id="cont_BotonNuevoComp" class=" relative inline-block w-full p-2" v-if="permisosUsuario.cc_Cobranza">
                            <button id="btn_Historico"
                                    class="flex 
                                        w-full
                                        bg-yellow-600
                                        rounded-lg
                                        border-solid
                                        border-yellow-500
                                        border-b-2
                                        p-2
                                        text-white
                                        font-bold"
                                    @click="mostrarCuentaCorriente()">
                                <i class="fas fa-file-invoice-dollar my-auto mx-3"></i> <span class="mx-auto">Cuenta Corriente</span> <i class="w-5 my-auto"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="cont_contenido"
                    v-show="Contenido == true" class="relative w-full">
                    <NuevaCobranza v-show="Nuevo == true" :idCliente="idCliente" :facturasCliente="facturasCliente" />
                    <GridCobranzas v-show="Grid == true" :numFactura="numFactura"/>
                    <CuentaCorriente v-show="cuentaCorriente == true" :EncCuentaCorriente="EncCuentaCorriente"/>
                </div>
            </div>
            <div class=" fixed block top-0" v-if="modalChatObservComp == true">
                <ChatObservaciones class="z-50"/>
            </div>
            <div class=" fixed block top-0" v-if="modalInfoCliente == true">
                <InfoCliente class="z-50" :InformacionCliente="InformacionCliente"/>
            </div>
        </div>
    </div>
</template>

<script>
import auth from "@/logic/auth";
import { mapState, mapMutations } from 'vuex'
import axios from "axios";
import NuevaCobranza from '../../components/Cobranza/NuevaCobranza.vue'
import GridCobranzas from '../../components/Cobranza/GridCobranzas.vue'
import ChatObservaciones from '../../components/Cobranza/ChatObservaciones.vue'
import CuentaCorriente from '../../components/Cobranza/CuentaCorriente.vue'
import InfoCliente from '../../components/Cobranza/InfoCliente.vue'

export default{
    components: {
        NuevaCobranza,
        GridCobranzas,
        ChatObservaciones,
        CuentaCorriente,
        InfoCliente
    },
    data(){
        return {
            Contenido: false,

            Nuevo: false,
            Grid: false,
            cuentaCorriente: false,

            numFactura: "",
            Cedula: "",
            NombreCliente: "",
            tipoFacturacion: "",
            ZonaCliente: "",
            CorteCliente: "",
            idCliente: "",

            campañaActual: "",

            actividadCliente: "",
            clasificacionCliente: "",
            cupoCliente: "",
            devolucionCliente: "",
            facturadoCliente: "",
            pagosCliente: "",
            saldoCliente: "",

            InformacionCliente: {},

            facturasCliente: {},

            EncCuentaCorriente: {},
        }
    },
    methods: {
        getCliente() {
            if(this.cuentaCorriente){
                this.abrirCuentaCorriente()
            }
            this.Contenido = true
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/Clientes/GetClientesCobSP/" + this.numFactura, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    //console.log(result.data[0])
                    this.Cedula = result.data[0].numDocumento
                    this.NombreCliente = result.data[0].nombre
                    this.tipoFacturacion = result.data[0].tipoFacturacion
                    this.ZonaCliente = result.data[0].codigoZona
                    this.CorteCliente = result.data[0].corte
                    this.idCliente = result.data[0].idCliente
                    this.clasificacionCliente = result.data[0].clasificacion
                    this.cupoCliente = result.data[0].cupo
                    this.actividadCliente = result.data[ 0].actividad
                    this.devolucionCliente = result.data[0].devolucion
                    this.pagosCliente = result.data[0].pagos
                    this.facturadoCliente = result.data[0].facturado
                    this.campañaActual = result.data[0].campañaActual
                    this.InformacionCliente = {
                        departamento: result.data[0].departamento,
                        poblacion: result.data[0].poblacion,
                        ciudad: result.data[0].ciudad,
                        barrio: result.data[0].barrio,
                        telefono1: result.data[0].telefono1,
                        telefono2: result.data[0].telefono2,
                        direccionResidencia: result.data[0].direccionResidencia,
                        nombreCodeudor: result.data[0].nombreCodeudor,
                        telefonosCodeudor: result.data[0].telefonoCod1 + " // " + result.data[0].telefonoCod2,
                        nombreRF1: result.data[0].nombreRF1,
                        telefonosRF1: result.data[0].telefonosRF1,
                        nombreRF2: result.data[0].nombreRF2,
                        telefonosRF2: result.data[0].telefonosRF2,
                        nombreRP1: result.data[0].nombreRP1,
                        telefonosRP1: result.data[0].telefonosRP1,
                        nombreRP2: result.data[0].nombreRP2,
                        telefonosRP2: result.data[0].telefonosRP2,
                    }
                    this.getFactCliente()
                })
                .catch((err) => {
                    console.log(err);
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
        },
        getFactCliente() {
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/Clientes/GetFactClienteCobSP/" + this.numFactura, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    this.facturasCliente = result.data
                    this.closeModalCargando()
                })
                .catch((err) => {
                    console.log(err);
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
        },
        NuevaCobranza(){
            if(this.Contenido == false){
                this.getCliente()
            }
            this.Grid = false
            this.cuentaCorriente = false
            this.Nuevo = true
        },
        abrirGrid(){
            if(this.Contenido == false){
                this.getCliente()
            }
            this.openModalCargando()
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/EncCobranza/GetEncCobranzaSP/" + this.numFactura, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    this.setEncCobranza(result.data)
                    this.closeModalCargando()
                })
                .catch(err => {
                    console.log(err)
                    this.closeModalCargando()
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
            this.Nuevo = false
            this.cuentaCorriente = false
            this.Grid = true
        },
        abrirCuentaCorriente(){
            if(this.Contenido == false){
                this.getCliente()
            }
            this.openModalCargando()
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            axios
                .get(this.$hostname + "/Api/EncCuentaCorriente/GetEncCuentaCorrienteSP/" + this.idCliente, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    this.EncCuentaCorriente = result.data
                    this.saldoCliente = this.EncCuentaCorriente[0].saldoTotal
                    this.Nuevo = false
                    this.Grid = false
                    this.cuentaCorriente = true
                    this.closeModalCargando()
                })
                .catch(err => {
                    console.log(err)
                    this.closeModalCargando()
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
        },
        mostrarCuentaCorriente(){
            this.abrirCuentaCorriente()
        },
        abrirInfoCliente(){
            this.openModalInfoCliente(this.InformacionCliente)
        },
        ...mapMutations([
            "loadNavbar", 
            "hiddenNavbar", 
            "logueo", 
            "openNavCobranza",
            "closeModalChatObservComp",
            "openModalInfoCliente",
            "openModalCargando",
            "closeModalCargando",
            "setEncCobranza"
        ]),
    },
    created() {
        this.openModalCargando()
        this.numFactura = this.$route.params.numFactura;
        this.getCliente()
        this.openNavCobranza()
        try {
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            if (user.token == "" || user.token == undefined) {
                this.$alert(
                    "Por favor vuelve a ingresar a la plataforma",
                    "Expiro la Sesion!",
                    "error"
                );
                this.$router.push("/");
            } else {
                this.display = "block";
                this.usuario = user.usuario;
                this.idUsuarioAux = user.id;
                this.loadNavbar(user);
            }
        } catch (err) {
            this.$alert(
                "Por favor vuelve a ingresar a la plataforma",
                "Expiro la Sesion!",
                "error"
            );
            this.$router.push("/");
        }
    },
    computed: {
        ...mapState(["modalChatObservComp","modalInfoCliente","permisosUsuario","infoAgentesWolkvox","MotivosLLamadasCobranza","EncCobranza"]),
        infoAgentesWolkvoxIdCampActiva(){
            if(this.infoAgentesWolkvox == undefined){
                return undefined
            }else{
                return this.infoAgentesWolkvox.idCampActiva
            }
        }
    }
}
</script>

<style>
*:focus {
    outline: 0 !important;
}
input {
    padding: 2px;
}
button{
    transition: 0.2s ease-in-out;
}
button:hover{
    transform: scale(1.05);
}
.cont_Camp-Wolkvox {
    top: -0.5rem;
    position: relative;
}
.Camp-Wolkvox {
    display: flex;
    background: #f2950b;
    color: #000;
    border-radius: 1rem;
    padding: 0.6rem;
    font-size: 1.1rem;
    font-weight: 500;
    position: relative;
    align-items: center;
    cursor: not-allowed;
}
.Camp-Wolkvox img {
    width: 2rem;
    filter: invert(1);
    margin-right: 0.5rem;
}
</style>