<template>
    <div id="cont_Main">
        <div id="cont_NuevaCobranza" class="grid grid-cols-1 md:grid-cols-4 gap-6">
            <div id="cont_numFactura">
                <span class=" relative block w-full text-left">Motivos Cobranza: *</span> 
                <select
                    id="buscarzona"
                    v-model="Motivo"
                    class="border rounded-xl shadow h-9 bg-white w-full"
                >
                    <option disabled>Motivos Corbanza</option>
                    <option v-for="motivo in MotivosLLamadasCobranza" :value="motivo" :key="motivo.numCodigo">
                        {{ motivo.descripcionMotivo }}
                    </option>
                </select>
            </div>
            <div id="cont_numFactura">
                <span class=" relative block w-full text-left"># Factura // Campaña: *</span> 
                <select
                    id="buscarzona"
                    v-model="factCliente"
                    class="border rounded-xl shadow h-9 bg-white w-full"
                >
                    <option disabled># Factura // Campaña</option>
                    <option v-for="fact in facturasCliente" :value="fact" :key="fact.registroNo">
                        {{ fact.numFactura + " // " + fact.campaniaFactura }}
                    </option>
                </select>
            </div>
            <div id="cont_Observacion" class="md:col-span-2">
                <span class=" relative block w-full text-left">Observacion: *</span> 
                <textarea type="text" 
                    id="Observacion" 
                    v-model="observacion" 
                    class="w-full
                            border-solid 
                            border-gray-600 
                            border-b-2
                            rounded-md" 
                    placeholder="Observacion"
                    rows="1" ></textarea>
            </div>
        </div>
        <div id="cont_NuevoCobranza" class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div id="cont_EsCobranza" class="my-4" v-if="permisosUsuario.cc_Cobranza">
                <input
                    id="esCompromiso"
                    name="esCompromiso"
                    v-model="esCompromiso"
                    type="checkbox"
                    class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded inline-block"
                />
                <label for="remember_me" class="ml-2 inline-block">
                    Es Compromiso de Pago?
                </label>
            </div>
            <div id="cont_FechaCobranza" v-if="esCompromiso">
                <span class=" relative block w-full text-left">Fecha Compromiso: *</span> 
                <input type="date" 
                    id="FechaCobranza" 
                    v-model="fechaComp" 
                    class="w-full
                            border-solid 
                            border-gray-600 
                            border-b-2
                            rounded-md"
                >
            </div>
        </div>
        <div class="cont_btns">
            <div v-if="infoAgentesWolkvoxIdCampActiva !== undefined">
                <button id="btn_cargarTipificacionWolkvox"
                        v-if="infoAgentesWolkvoxIdCampActiva !== 0"
                        class="bg-green-600
                            rounded-lg
                            p-2
                            text-white
                            font-bold
                            mt-4"
                        @click="cargarTipificacionWolkvox()">
                    <i><img src="/img/BuscarWolkvox.png" alt=""></i>
                </button>
            </div>
            <button id="btn_guardarCobranza"
                    class="bg-green-600
                        rounded-lg
                        border-solid
                        border-green-500
                        border-b-2
                        p-2
                        text-white
                        font-bold
                        mt-4"
                    @click="guardarCobranza()">
                <i class="fas fa-save"></i> Guardar {{ esCompromiso ? "Compromiso" : "Cobranza" }}
            </button>
        </div>
    </div>
</template>

<script>
import auth from "@/logic/auth"
import { mapMutations, mapState } from 'vuex'
export default {
    name: 'NuevaCobranza',
    props: {
        idCliente: 0,
        facturasCliente: {},
    },
    data(){
        return{
            fechaComp: "",
            factCliente: {},
            observacion: "",
            Motivo: {},
            esCompromiso: false,

            enviar: false,
            mostrarBtnCargarTipificacionWolkvox: false,
            
            EncCobranza: {},
            DetCobranza: {},
        }
    },
    methods: {
        ...mapMutations(["openModalCargando","closeModalCargando"]),
        guardarCobranza(){
            if(!this.esCompromiso){
                if(this.Motivo == {} || this.Motivo == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa un Motivo para guardar la cobranza",
                        "Ingresa un Motivo!",
                        "error"
                    );
                }else{
                    if(this.factCliente == {} || this.factCliente == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Factura para guardar la cobranza",
                            "Ingresa una Factura!",
                            "error"
                        );
                    }else{
                        if(this.observacion ==  "" || this.observacion == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Observacion para guardar la cobranza",
                                "Ingresa una Observacion!",
                                "error"
                            );
                        }else{
                            this.enviar = true
                        }
                    }
                }
                if(this.factCliente == {} || this.factCliente == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Factura para guardar la cobranza",
                        "Ingresa una Factura!",
                        "error"
                    );
                }else{
                    if(this.observacion ==  "" || this.observacion == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Observacion para guardar la cobranza",
                            "Ingresa una Observacion!",
                            "error"
                        );
                    }else{
                        if(this.Motivo == {} || this.Motivo == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa un Motivo para guardar la cobranza",
                                "Ingresa un Motivo!",
                                "error"
                            );
                        }else{
                            this.enviar = true
                        }
                    }
                }
                if(this.observacion ==  "" || this.observacion == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Observacion para guardar la cobranza",
                        "Ingresa una Observacion!",
                        "error"
                    );
                }else{
                    if(this.Motivo == {} || this.Motivo == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa un Motivo para guardar la cobranza",
                            "Ingresa un Motivo!",
                            "error"
                        );
                    }else{
                        if(this.factCliente == {} || this.factCliente == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Factura para guardar la cobranza",
                                "Ingresa una Factura!",
                                "error"
                            );
                        }else{
                            this.enviar = true
                        }
                    }
                }
            }
            if (this.esCompromiso){
                if(this.Motivo == {} || this.Motivo == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa un Motivo para guardar el compromiso",
                        "Ingresa un Motivo!",
                        "error"
                    );
                }else{
                    if(this.factCliente == {} || this.factCliente == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Factura para guardar el compromiso",
                            "Ingresa una Factura!",
                            "error"
                        );
                    }else{
                        if(this.observacion ==  "" || this.observacion == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Observacion para guardar el compromiso",
                                "Ingresa una Observacion!",
                                "error"
                            );
                        }else{
                            if(this.fechaComp ==  "" || this.fechaComp == undefined){
                                this.enviar = false
                                this.$alert(
                                    "Por favor ingresa una Fecha para guardar el compromiso",
                                    "Ingresa una Fecha!",
                                    "error"
                                );
                            }else{
                                this.enviar = true
                            }
                        }
                    }
                }
                if(this.factCliente == {} || this.factCliente == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Factura para guardar el compromiso",
                        "Ingresa una Factura!",
                        "error"
                    );
                }else{
                    if(this.observacion ==  "" || this.observacion == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Observacion para guardar el compromiso",
                            "Ingresa una Observacion!",
                            "error"
                        );
                    }else{
                        if(this.fechaComp ==  "" || this.fechaComp == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Fecha para guardar el compromiso",
                                "Ingresa una Fecha!",
                                "error"
                            );
                        }else{
                            if(this.Motivo == {} || this.Motivo == undefined){
                                this.enviar = false
                                this.$alert(
                                    "Por favor ingresa un Motivo para guardar el compromiso",
                                    "Ingresa un Motivo!",
                                    "error"
                                );
                            }else{
                                this.enviar = true
                            }
                        }
                    }
                }
                if(this.observacion ==  "" || this.observacion == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Observacion para guardar el compromiso",
                        "Ingresa una Observacion!",
                        "error"
                    );
                }else{
                    if(this.fechaComp ==  "" || this.fechaComp == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Fecha para guardar el compromiso",
                            "Ingresa una Fecha!",
                            "error"
                        );
                    }else{
                        if(this.Motivo == {} || this.Motivo == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa un Motivo para guardar el compromiso",
                                "Ingresa un Motivo!",
                                "error"
                            );
                        }else{
                            if(this.factCliente == {} || this.factCliente == undefined){
                                this.enviar = false
                                this.$alert(
                                    "Por favor ingresa una Factura para guardar el compromiso",
                                    "Ingresa una Factura!",
                                    "error"
                                );
                            }else{
                                this.enviar = true
                            }
                        }
                    }
                }
                if(this.fechaComp ==  "" || this.fechaComp == undefined){
                    this.enviar = false
                    this.$alert(
                        "Por favor ingresa una Fecha para guardar el compromiso",
                        "Ingresa una Fecha!",
                        "error"
                    );
                }else{
                    if(this.observacion ==  "" || this.observacion == undefined){
                        this.enviar = false
                        this.$alert(
                            "Por favor ingresa una Observacion para guardar el compromiso",
                            "Ingresa una Observacion!",
                            "error"
                        );
                    }else{
                        if(this.fechaComp ==  "" || this.fechaComp == undefined){
                            this.enviar = false
                            this.$alert(
                                "Por favor ingresa una Fecha para guardar el compromiso",
                                "Ingresa una Fecha!",
                                "error"
                            );
                        }else{
                            if(this.Motivo == {} || this.Motivo == undefined){
                                this.enviar = false
                                this.$alert(
                                    "Por favor ingresa un Motivo para guardar el compromiso",
                                    "Ingresa un Motivo!",
                                    "error"
                                );
                            }else{
                                this.enviar = true
                            }
                        }
                    }
                }
            }
            if(this.enviar){
                this.enviarCobranza()
            }
        },
        enviarCobranza(){
            var user = auth.getUserLogged();
            user = JSON.parse(user);
            //console.log(user)
            if (this.esCompromiso){
                this.EncCobranza = {
                    idCliente: this.idCliente,
                    fechaCreacion: new Date(),
                    usuarioCreacion: user.loginUsuario,
                    campañaFactura: this.factCliente.campaniaFactura,
                    numFactura: this.factCliente.numFactura,
                    idMotivo: this.Motivo.numCodigo,
                    compromisoPago: true,
                    fechaCompromiso: new Date(this.fechaComp),
                    cumplioPago: false
                }
            } else {
                this.EncCobranza = {
                    idCliente: this.idCliente,
                    fechaCreacion: new Date(),
                    usuarioCreacion: user.loginUsuario,
                    campañaFactura: this.factCliente.campaniaFactura,
                    numFactura: this.factCliente.numFactura,
                    idMotivo: this.Motivo.numCodigo,
                    compromisoPago: false,
                    fechaCompromiso: new Date("1/1/1900 00:00:00"),
                    cumplioPago: false
                }
            }
            axios
                .post(this.$hostname + "/Api/EncCobranza", this.EncCobranza, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    this.idCobranza = result.data.data.numCodigo
                    this.DetCobranza = {
                        idCobranza: this.idCobranza,
                        fechaIngreso: new Date(),
                        usuarioIngreso: user.loginUsuario,
                        observacion: this.observacion,
                        esCartera: this.permisosUsuario.cc_Cobranza,
                    }
                    axios
                        .post(this.$hostname + "/Api/DetCobranza", this.DetCobranza, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + user.token,
                            },
                        })
                        .then((result) => {
                            this.Motivo = {}
                            this.factCliente = this.facturasCliente[0]
                            this.observacion = ""
                            this.fechaComp = ""
                            this.esCompromiso = false
                            this.$alert(
                                "Se grabó el nuevo cobranza para la fecha " + this.fechaComp + " con el siguiente ID: #" + this.idCobranza,
                                "Éxito!",
                                "success"
                            )
                        })
                        .catch((err) => {
                            console.log(err)
                            //console.log(this.EncCobranza)
                            //console.log(this.DetCobranza)
                            this.$alert(
                                "Por favor vuelve a ingresar a la plataforma",
                                "Expiro la Sesion!",
                                "error"
                            );
                        });
                })
                .catch((err) => {
                    console.log(err)
                    //console.log(this.EncCobranza)
                    //console.log(this.DetCobranza)
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                });
            
        },
        cargarTipificacionWolkvox(){
            var user = auth.getUserLogged();
            user = JSON.parse(user);

            this.openModalCargando()
            axios
                .post(this.$hostname + "/api/WolkvoxAPI/cargarTipificacionWolkvox/",
                {
                    token: this.infoAgentesWolkvox.token,
                    numFactura: this.facturasCliente[0].numFactura,
                    idCamp: this.infoAgentesWolkvoxIdCampActiva,
                    idAgente: this.infoAgentesWolkvox.idAgente
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .then((result) => {
                    var continuar = true
                    var resultadosConsulta = result.data
                    
                    resultadosConsulta.forEach((element,index) => {
                        if(element.result != "CLEAN"){
                            resultadosConsulta[index].comments = element.comments.substr(18)
                            resultadosConsulta[index].date = new Date(element.date)
                            //resultadosConsulta[index].recall_date = new Date(element.recall_date)
                            if(element.cod_act != ""){
                                resultadosConsulta[index].ObjMotivo = this.MotivosLLamadasCobranza.filter((element2)=>{
                                    return element2.idMotivoTipificacionWolkvox == element.cod_act
                                })[0]
                            }
                        }
                    });
                    console.log(resultadosConsulta)

                    this.Motivo = resultadosConsulta[0].ObjMotivo
                    this.observacion = resultadosConsulta[0].comments
                    
                    this.closeModalCargando()
                })
                .catch((err) => {
                    this.closeModalCargando()
                    console.log(err);
                    this.$alert(
                        "Comunicate con Sistemas",
                        "No se encontro gestion para la factura consultada",
                        "error"
                    );
                    this.$router.push(
                        "/Cobranza/" + 
                        (this.UbicacionCobranza == "Pendientes") 
                            ? this.UbicacionCobranza 
                            : (this.UbicacionCobranza == "Gestionadas") 
                                ? this.UbicacionCobranza 
                                : "Inicio"
                    );
                });
        }
    },
    created() {
        if(this.permisosUsuario.cc_Cobranza == false){
            this.esCompromiso = true
        }
        this.factCliente = this.facturasCliente[0]
        if(this.infoAgentesWolkvoxIdCampActiva != 0){
            this.mostrarBtnCargarTipificacionWolkvox = true
        }
    },
    computed: {
        ...mapState(["permisosUsuario","infoAgentesWolkvox","MotivosLLamadasCobranza","UbicacionCobranza"]),
        infoAgentesWolkvoxIdCampActiva(){
            if(this.infoAgentesWolkvox == undefined){
                return undefined
            }else{
                return this.infoAgentesWolkvox.idCampActiva
            }
        }
    }
}
</script>

<style>
input, textarea {
    padding: 2px;
}
#btn_cargarTipificacionWolkvox {
    position: absolute;
    left: 0rem;
    width: 2.7rem;
    background: #f2950b;
    border-bottom: solid 0.2rem #ffb74a;
}
</style>